import React, { useState, useRef } from 'react';
import {
  Box, HStack, Image, Text, Icon, Menu, MenuButton, MenuList, MenuItem, IconButton,
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
  AlertDialogOverlay, Button, useToast, useDisclosure, Modal, ModalOverlay, ModalContent,
  ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter,
  RangeSlider, RangeSliderTrack, RangeSliderFilledTrack, RangeSliderThumb
} from '@chakra-ui/react';
import { FaUserAlt, FaBook, FaVideo, FaEllipsisV } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { urlvideo } from '../services/urls';
import axiosInstance from '../services/axiosInstance';

const CourseCard2 = ({ course, refresh }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const { isOpen: isModalOpen, onOpen, onClose } = useDisclosure();
  const [newChapterName, setNewChapterName] = useState('');
  const [numParties, setNumParties] = useState(1);
  const [parties, setParties] = useState(['']);
  const [chapters, setChapters] = useState(course.chapitres); // State for storing chapters
  const { isOpen: isReorderModalOpen, onOpen: onOpenReorderModal, onClose: onCloseReorderModal } = useDisclosure();

  const handleAddChapter = () => {
    onOpen();
  };

  const handleSaveChapter = async () => {
    try {
      const newChapter = {
        nom: newChapterName,
        parties: parties.map((part) => ({ nom: part, videos: [] }))
      };
      await axiosInstance.post(`Courses/${course._id}/addChapter`, newChapter); // Replace with your add chapter API endpoint
      toast({
        title: "Chapter added.",
        description: "The new chapter has been added successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refresh(); // Refresh courses after adding the chapter
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to add the chapter.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      onClose(); // Close the modal in any case
    }
  };

  const handlePartNameChange = (index, value) => {
    const updatedParties = [...parties];
    updatedParties[index] = value;
    setParties(updatedParties);
  };

  const onCloseAlert = () => setIsOpen(false);

  const handleDeleteCourse = async () => {
    try {
      await axiosInstance.delete(`Courses/${course._id}/delete`); // Replace with your delete API endpoint
      toast({
        title: "Course deleted.",
        description: "The course has been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refresh(); // Refresh courses after deletion
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to delete the course.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      onCloseAlert(); // Close the dialog in any case
    }
  };

  const navigateToCourseManagement = () => {
    navigate(`/admin/courses/${course._id}`);
  };

  const handleOpenDialog = () => setIsOpen(true);

  // Reorder chapters using react-beautiful-dnd
  const onDragEnd = async (result) => {
    if (!result.destination) return;
  
    const reorderedChapters = Array.from(chapters);
    const [movedChapter] = reorderedChapters.splice(result.source.index, 1);
    reorderedChapters.splice(result.destination.index, 0, movedChapter);
  
    setChapters(reorderedChapters);
  console.log(...reorderedChapters)
    try {
      // Make an API call to update the chapter order in the backend
      await axiosInstance.put(`Courses/${course._id}/updateChaptersOrder`, { chapitres: reorderedChapters });
      toast({
        title: "Chapters reordered.",
        description: "The chapters have been successfully reordered.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refresh(); // Optionally, refresh the course data
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to reorder the chapters.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      border="1px solid #E2E8F0"
      borderRadius="md"
      overflow="hidden"
      bg="white"
      width="100%"
      height="500px"
      mb={4}
      cursor="pointer"
      position="relative"
    >
      <Image
        src={`${urlvideo}${course.vignetteImage}`}
        alt={course.title}
        width="100%"
        height="300px"
        objectFit="cover"
      />
      <Box p={4}>
        <Text fontWeight="bold" fontSize="lg" mb={2}>
          {course.name}
        </Text>
        <HStack spacing={2} mb={2}>
          <Icon as={FaUserAlt} color="gray.500" />
          <Text fontSize="sm" color="gray.600">
            {course.teacherName}
          </Text>
        </HStack>
        <HStack spacing={4} mb={4}>
          <HStack spacing={1}>
            <Icon as={FaBook} color="gray.500" />
            <Text fontSize="sm" color="gray.600">
              {chapters.length} chapitres
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Icon as={FaVideo} color="gray.500" />
            <Text fontSize="sm" color="gray.600">
              {course.videos} vidéos
            </Text>
          </HStack>
        </HStack>
      </Box>

      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FaEllipsisV />}
          variant="ghost"
          position="absolute"
          top="10px"
          right="10px"
        />
        <MenuList>
          <MenuItem onClick={navigateToCourseManagement}>Gérer le cours</MenuItem>
          <MenuItem onClick={onOpenReorderModal}>Reorder chapitres</MenuItem> {/* Added Reorder Menu Item */}
          <MenuItem onClick={handleOpenDialog} color="red.500">Supprimer le cours</MenuItem>
        </MenuList>
      </Menu>

  

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer le cours
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr de vouloir supprimer ce cours ? Cette action est irréversible.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDeleteCourse} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={isModalOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ajouter un nouveau chapitre</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Nom du chapitre</FormLabel>
              <Input
                value={newChapterName}
                onChange={(e) => setNewChapterName(e.target.value)}
                placeholder="Entrez le nom du chapitre"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Nombre de parties</FormLabel>
              <RangeSlider
                aria-label={['min', 'max']}
                min={1}
                max={10}
                step={1}
                value={[numParties]}
                onChange={(val) => {
                  setNumParties(val[0]);
                  setParties(Array.from({ length: val[0] }, (_, i) => parties[i] || ''));
                }}
              >
                <RangeSliderTrack>
                  <RangeSliderFilledTrack />
                </RangeSliderTrack>
                <RangeSliderThumb index={0} />
              </RangeSlider>
              <Text>{numParties} parties</Text>
            </FormControl>
            {Array.from({ length: numParties }, (_, i) => (
              <FormControl key={i} mb={4}>
                <FormLabel>Nom de la partie {i + 1}</FormLabel>
                <Input
                  value={parties[i]}
                  onChange={(e) => handlePartNameChange(i, e.target.value)}
                  placeholder={`Entrez le nom de la partie ${i + 1}`}
                />
              </FormControl>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSaveChapter}>
              Sauvegarder
            </Button>
            <Button variant="ghost" onClick={onClose}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Reorder Modal */}
      <Modal isOpen={isReorderModalOpen} onClose={onCloseReorderModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Réorganiser les chapitres</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="reorderChapters">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    p={4}
                  >
                    {chapters.map((chapter, index) => (
                      <Draggable key={chapter._id} draggableId={chapter._id} index={index}>
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            p={2}
                            mb={2}
                            border="1px solid #E2E8F0"
                            borderRadius="md"
                            bg="gray.100"
                          >
                            <Text>{chapter.nom}</Text>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onCloseReorderModal}>
              Sauvegarder
            </Button>
            <Button variant="ghost" onClick={onCloseReorderModal}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CourseCard2;
