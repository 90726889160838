import React, { useState, useEffect } from 'react';
import { fetchCourses, fetchCoursesforme } from '../services/courseService';

import { useNavigate } from 'react-router-dom';

import {
    Box,
    VStack,
    Avatar,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Icon,
    useMediaQuery,
    Flex,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    TabList,
    Heading,
    Tabs,
    AccordionIcon,
    TabPanels,
    Image,
    Card,
    TabPanel,
    Tab,
    Text,
    Textarea,
    Button,
    HStack,
    Divider,
} from "@chakra-ui/react";
import { FiFileText, FiHome, FiSettings, FiUser, FiLogOut, FiBook, FiLock, FiUnlock ,FiVideo  } from "react-icons/fi";
import { useParams } from 'react-router-dom';
import { fetchCourseById } from '../services/courseService';
import VideoPlayer from '../shared/videoplayer';
import axios from 'axios';
import axiosInstance from '../services/axiosInstance';
import Sidebar from '../shared/Sidebar';
import { urlvideo } from '../services/urls';



const MiddleContent = ({ courseData, selectedVideo, setSelectedVideo }) => {
    return (
        <Box w={{ base: "100%", md: "70%" }} p={6}>
            <VStack align="start" spacing={6}>
                <Text fontSize={{ base: "sm", md: "md" }} color="#032D7F">Cours / {courseData?.name}</Text>
                <Flex justify="space-between" w="100%" align="center" flexDirection={{ base: "column", md: "row" }}>
                    <VStack align="start" spacing={1}>
                        <Heading as="h2" size={{ base: "md", md: "lg" }} color="#032D7F">{courseData?.name}</Heading>
                        <Text fontSize={{ base: "sm", md: "md" }}>{courseData?.teacherName}</Text>
                    </VStack>
                    <Text fontSize={{ base: "sm", md: "md" }}>Chapitre {selectedVideo?.chapterIndex + 1 || 1}/{courseData?.chapitres?.length || 0}</Text>
                </Flex>
                {selectedVideo ? (
                    <VideoPlayer idvdocipher={selectedVideo.idvdocipher} />
                ) : (
                    <Text>Aucune vidéo sélectionnée. Veuillez sélectionner une vidéo à lire.</Text>
                )}
                <Tabs isManual variant="enclosed" w="100%" mt={4}>
                    <TabList>
                        <Tab>Notes</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Flex justifyContent="space-between" flexDirection={{ base: "column", md: "row" }} mt={4}>
                                <Text>Description</Text>
                                <Card w={{ base: "100%", md: "30%" }} p={4} borderWidth="1px" borderRadius="md">
                                    <VStack align="center">
                                        <Avatar src="../assets/oussama-loussifi.jpeg" size="lg" />
                                        <Text fontWeight="700" textAlign="center">{courseData?.teacherName}</Text>
                                        <Text fontSize="sm" textAlign="center" fontWeight="500">Professeur de sciences de gestion et finance (encadrant)</Text>
                                        <Text fontSize="sm" textAlign="center" color="gray.600">Fort d'une pédagogie innovante et d'une profonde bienveillance, il accompagne ses étudiants en sciences de gestion et finance dans leur développement personnel et professionnel, les préparant ainsi à relever les défis du monde économique.</Text>
                                    </VStack>
                                </Card>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </VStack>
        </Box>
    );
};

const RightSidebar = ({ courseData, selectedVideo, setSelectedVideo }) => {
    const [commentaire, setCommentaire] = useState("");
    const [userName] = useState("Nom d'utilisateur"); // Update with actual user data if available

    const handleCommentaireChange = (event) => {
        setCommentaire(event.target.value);
    };

    const handleCommentaireSubmit = async () => {
        if (!selectedVideo || !commentaire.trim()) return;

        try {
            const response = await axiosInstance.post(`/courses/add/${courseData._id}/videos/${selectedVideo._id}/comments`, {
                message: commentaire,
                sender: userName,
                time: new Date().toISOString(),
            });
            console.log('Commentaire added:', response.data);
            setCommentaire(""); // Clear the textarea
        } catch (error) {
            console.error('Error adding commentaire:', error);
        }
    };

    const handleAttachmentDownload = (attachmentUrl) => {
        const link = document.createElement('a');
        link.href = attachmentUrl;
        link.target = "_blank"
        link.download = attachmentUrl.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Box w={{ base: "100%", md: "25%" }} h={{ base: "auto", md: "100vh" }} p={6} borderLeft="1px solid" borderColor="gray.200" marginTop={"80px"} maxH={"100vh"} overflow={"scroll"}>
            <Text fontSize="lg" color="#032D7F">Liste des chapitres</Text>
            <Accordion allowMultiple>
                {courseData?.chapitres?.map((chapitre, chapterIndex) => (
                    <AccordionItem key={chapterIndex} bg="blue.50" borderRadius="md" mb={2}>
                        <h2>
                            <AccordionButton _expanded={{ bg: "#032D7F", color: "white" }}>
                                <Box as="span" flex="1" textAlign="left">
                                    Chapitre {chapterIndex + 1} : {chapitre.nom}
                                </Box>
                                <Icon as={chapitre.isOpen ? FiUnlock : FiLock} w={6} h={6} mr={2} />
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            {chapitre.isOpen ? (
                                <Accordion allowMultiple>
                                    {chapitre.parties.map((partie, partieIndex) => (
                                        <AccordionItem key={partieIndex} bg="gray.100" borderRadius="md" mb={1}>
                                            <h2>
                                                <AccordionButton _expanded={{ bg: "#032D7F", color: "white" }}>
                                                    <Box as="span" flex="1" textAlign="left">
                                                        Partie {partieIndex + 1} : {partie.name}
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                {partie.videos.length > 0 || (partie.attachments && partie.attachments.length > 0) ? (
                                                    <>
                                                        {partie.videos.map((video, videoIndex) => (
                                                            <Flex
                                                                key={videoIndex}
                                                                onClick={() => setSelectedVideo({ ...video, chapterIndex, partieIndex, videoIndex })}
                                                                cursor="pointer"
                                                                bg={selectedVideo?.id === video._id ? "#D3E5FF" : "#f7f9fc"}
                                                                _hover={{ bg: "#E2E8F0" }}
                                                                p={2}
                                                                borderRadius="md"
                                                                align="center"
                                                            >
                                                                <Icon as={FiVideo  } w={5} h={5} mr={2} color="#032D7F" />
                                                                <Text color={selectedVideo?.id === video._id ? "#032D7F" : "inherit"}>
                                                                    Vidéo {videoIndex + 1} : {video.nomvideo}
                                                                </Text>
                                                            </Flex>
                                                        ))}
                                                        {partie.attachments && partie.attachments.length > 0 && (
                                                            <Box mt={4}>
                                                                <Text fontSize="sm" fontWeight="bold" color="#032D7F" mb={2}>PDFs:</Text>
                                                                {partie.attachments.map((attachment, attachmentIndex) => (
                                                                    <Flex
                                                                        key={attachmentIndex}
                                                                        alignItems="center"
                                                                        bg="#f0f4ff"
                                                                        p={3}
                                                                        borderRadius="md"
                                                                        mb={2}
                                                                        boxShadow="md"
                                                                        _hover={{ bg: "#e0e6ff", transform: "scale(1.02)" }}
                                                                        cursor="pointer"
                                                                        onClick={() => handleAttachmentDownload(urlvideo + attachment.url)}
                                                                        transition="all 0.2s"
                                                                    >
                                                                        <Icon as={FiFileText} w={5} h={5} color="#2B6CB0" mr={3} />
                                                                        <Text color="#2B6CB0" fontWeight="600">
                                                                            {attachment.nom}
                                                                        </Text>
                                                                    </Flex>
                                                                ))}
                                                            </Box>
                                                        )}

                                                    </>
                                                ) : (
                                                    <Text color="gray.500">Partie fermée</Text>
                                                )}
                                            </AccordionPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            ) : (
                                <Text color="red.500">Chapitre verrouillé. Veuillez compléter les chapitres précédents ou obtenir l'accès pour déverrouiller.</Text>
                            )}
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Box>
    );
};

const Layout = () => {
    const { id } = useParams();
    const [courseData, setCourseData] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        
        const fetchCourse = async () => {
            try {
                const data = await fetchCourseById(id);
               
                let data2=await fetchCoursesforme()
                console.log(data)
                let test=data2.findIndex(el=>el._id===data._id)
                console.log(test)
if(test){
    setCourseData(data.data);
    setSelectedVideo(data.data.chapitres?.[0]?.parties?.[0]?.videos?.[0] || null);
}
else{
    navigate("/courses")
}
               
            } catch (error) {
                console.error('Échec de la récupération des données du cours :', error);
            }
        };

        fetchCourse();
       

       
    }, [id]);

    return (
        <Flex direction={{ base: "column", md: "row" }} ml={{ base: 0, md: "5%" }}>
            <Sidebar />
            <MiddleContent courseData={courseData} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />
            <RightSidebar courseData={courseData} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />
        </Flex>
    );
};

export default Layout;