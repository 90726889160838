import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { fetchCourses } from '../services/courseService';
import { FaPlus, FaTrash } from 'react-icons/fa';
import Sidebar2 from '../shared/Sidebar2';
import { useTable } from 'react-table';
import userService from '../services/userService';
import RegisterUsersButton, { registerUsers } from './js';

const UserAdminManagement = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [matiersOptions, setmatiersOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;
  const toast = useToast();

  useEffect(() => {
    fetchUsers();
    fetchcoursesNames()

  }, []);

  useEffect(() => {
    filterUsers();
  }, [searchTerm, users]);
const fetchcoursesNames=async()=>{
  try{
    let data=await fetchCourses()
    data=data.map(el=>({label:el.name,value:el.name}))
    setmatiersOptions(data)
  }
  catch(error){

  }
}
  const fetchUsers = async () => {
    try {
      const fetchedUsers = await userService.getUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Échec de la récupération des utilisateurs:', error);
      toast({
        title: 'Erreur',
        description: 'Échec de la récupération des utilisateurs.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const filterUsers = () => {
    if (!searchTerm) {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        users.filter(user =>
          (user.username && user.username.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      );
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    if (!selectedUser.name || !selectedUser.email) {
      setErrorMessage('Tous les champs doivent être remplis.');
      return false;
    }
    if (!validateEmail(selectedUser.email)) {
      setErrorMessage("L'adresse email est invalide.");
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleAddUser = () => {
    setSelectedUser({ name: '', email: '' });
    onOpen();
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    onOpen();
  };

  const handleDeleteUser = (user) => {
    setDeletingUser(user._id);
    onOpen();
  };

  const confirmDeleteUser = async () => {
    try {
      await userService.deleteUser(deletingUser);
      await fetchUsers();
      toast({
        title: 'Succès',
        description: 'Utilisateur supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Échec de la suppression de l’utilisateur:", error);
      toast({
        title: 'Erreur',
        description: "Échec de la suppression de l’utilisateur.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setDeletingUser(null);
    onClose();
  };

  const handleSaveUser = async () => {
    if (!validateFields()) {
      return;
    }

    try {
      if (selectedUser._id) {
        await userService.updateUser(selectedUser._id, selectedUser);
        await fetchUsers();
        toast({
          title: 'Succès',
          description: 'Utilisateur mis à jour avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        await userService.addUser({
          email: selectedUser.email,
          password: generatePassword(),
          username: selectedUser.name,
          matiers:selectedUser.matiers
        });
        await fetchUsers();
        toast({
          title: 'Succès',
          description: 'Utilisateur ajouté avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
    } catch (error) {
      console.error("Échec de l’enregistrement de l’utilisateur:", error);
      toast({
        title: 'Erreur',
        description: "Échec de l’enregistrement de l’utilisateur.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const paginatedUsers = filteredUsers.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nom',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <Flex gap={2}>
            <IconButton
              icon={<FaTrash />}
              aria-label="Supprimer l'utilisateur"
              size="sm"
              colorScheme="red"
              onClick={() => handleDeleteUser(row.original)}
            />
          </Flex>
        ),
      },
    ],
    [filteredUsers]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: paginatedUsers });
  
  const generatePassword = (length = 8) => {
    const charset = "0123456789";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password;
  };

  return (
    <Flex h="100vh" bg="#F7FAFC">
      <Sidebar2 />

      <Box flex="1" p={6} ml={{ base: '60px', lg: '250px' }}>
        <Heading mb={8} color="#032D7F">
          Gestion des Utilisateurs
        </Heading>
        <Flex mb={4} justify="space-between">
        <Input
            placeholder="Rechercher un utilisateur..."
            maxW="300px"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button leftIcon={<FaPlus />} colorScheme="blue" onClick={handleAddUser}>
            Ajouter Utilisateur
          </Button>
      
        </Flex>
        <Box bg="white" p={6} borderRadius="md" shadow="md">
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td style={{width:"33%"}} {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          <Flex mt={4} justify="center">
            {[...Array(totalPages).keys()].map((page) => (
              <Button
                key={page}
                onClick={() => handlePageChange(page)}
                colorScheme={page === currentPage ? 'blue' : 'gray'}
                mx={1}
              >
                {page + 1}
              </Button>
            ))}
          </Flex>
        </Box>

        <Modal isOpen={!!deletingUser} onClose={() => setDeletingUser(null)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmer la suppression</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p>Êtes-vous sûr de vouloir supprimer cet utilisateur ? Cette action est irréversible.</p>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={confirmDeleteUser}>
                Supprimer
              </Button>
              <Button variant="ghost" onClick={() => setDeletingUser(null)}>
                Annuler
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

     

<Modal isOpen={isOpen && !deletingUser} onClose={onClose}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>{selectedUser ? 'Modifier un Utilisateur' : 'Ajouter un Utilisateur'}</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Stack spacing={4}>
        <Input
          placeholder="Nom"
          value={selectedUser?.name || ''}
          onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })}
        />
        <Input
          placeholder="Email"
          value={selectedUser?.email || ''}
          onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
        />
        <Box>
        <ReactSelect
    isMulti
    options={matiersOptions} 
    placeholder="Sélectionner des Matières"
    value={(selectedUser?.matiers || []).map((el) => ({ label: el, value: el }))}
    onChange={(selected) =>
      setSelectedUser({ ...selectedUser, matiers: selected.map((item) => item.value) })
    }
  />
        </Box>
        {errorMessage && (
          <Box color="red.500" mt={2}>
            {errorMessage}
          </Box>
        )}
      </Stack>
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={handleSaveUser}>
        {selectedUser ? 'Sauvegarder' : 'Ajouter'}
      </Button>
      <Button variant="ghost" onClick={onClose}>
        Annuler
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>

      </Box>
    </Flex>
  );
};

export default UserAdminManagement;
