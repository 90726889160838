// src/components/CourseCard.js
import React from 'react';
import { Box, HStack, Image, Text, Icon } from '@chakra-ui/react';
import { FaUserAlt, FaBook, FaVideo } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { urlvideo } from './../services/urls';
import { fetchCourses, fetchCoursesforme } from '../services/courseService';


const CourseCard = ({ course }) => {
  const navigate = useNavigate();

  const navigateToCourseDetails = async() => {
    const data=await fetchCoursesforme()
    let test =data.find(el=>el._id===course._id)
    if(test){
      navigate(`/courses/${course._id}`);
    }
   else{
    alert("Pour accéder à ce cours, veuillez contacter le propriétaire de la plateforme")
   }
  };

  return (
    <Box
      border="1px solid #E2E8F0"
      borderRadius="md"
      overflow="hidden"
      bg="white"
      width="100%"
      height={"500px"}
      mb={4}
      cursor="pointer"
      onClick={navigateToCourseDetails}
    >
      <Image
        src={urlvideo+ course.vignetteImage}
        alt={course.title}
        width="100%"
        height="300px"
      />
      <Box p={4}>
        <Text fontWeight="bold" fontSize="lg" mb={2}>
          {course.name}
        </Text>
        <HStack spacing={2} mb={2}>
          <Icon as={FaUserAlt} color="gray.500" />
          <Text fontSize="sm" color="gray.600">
            {course.teacherName}
          </Text>
        </HStack>
        <HStack spacing={4} mb={4}>
          <HStack spacing={1}>
            <Icon as={FaBook} color="gray.500" />
            <Text fontSize="sm" color="gray.600">
              {course.chapitres.length} chapitres
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Icon as={FaVideo} color="gray.500" />
            <Text fontSize="sm" color="gray.600">
              {course.videos} vidéos
            </Text>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
};

export default CourseCard;
